(function($) {
  $(document).ready(function() {
    $(document).on('click', '#nav-icon3', function() {
      $(this).addClass('open');
      return $('.wrap').addClass('overlay');
    });
    $(document).on('hidden.bs.modal', '#menu-modal', function(e) {
      $('#nav-icon3').toggleClass('open');
      return $('.wrap').toggleClass('overlay');
    });
    $(document).on('click', '.accordion-item-header', function() {
      return $(this).siblings('.accordion-item-content').slideToggle();
    });
    $(document).on('click', '.items-list-element:not(.items-list-element--link)', function() {
      var get, object, requestUrl;
      $('.items-response-list').remove;
      $('.items-list-element').removeClass('active');
      $(this).addClass('active');
      object = $(this).data('object');
      get = $(this).data('get');
      requestUrl = `/${object}/${get}/`;
      // console.log requestUrl
      return $.ajax({
        url: requestUrl,
        method: 'GET',
        data: {
          id: $(this).data('id')
        },
        success: function(data) {
          // console.log data
          if (data.items.length > 0) {
            return $('.items-response').html(data.view);
          }
        },
        error: function(data) {
          return console.log(`error: ${data}`);
        }
      });
    });
    $(document).on('click', '#btnGroupSearchList .dropdown-item', function() {
      var _text;
      _text = $(this).text();
      $('#btnGroupSearchList .dropdown-item').removeClass('active');
      $(this).addClass('active');
      return $('#btnGroupSearchType span').text(_text);
    });
    return $(document).on('submit', '#search-form', function(e) {
      var data, q, type;
      e.preventDefault();
      data = $(this).serialize();
      type = $('.dropdown-item.active').data('type');
      q = $('#btnGroupSearchInput').val();
      // console.log data
      // console.log type
      data = {
        q: q,
        type: type
      };
      return $.ajax({
        url: '/search/index/',
        method: 'GET',
        data: data,
        success: function(data) {
          // console.log data
          return $('.search-results').html(data);
        },
        // if data.items.length > 0
        //   $('.items-response').html data.view;
        error: function(data) {
          return console.log(`error: ${data}`);
        }
      });
    });
  });
  $(document).on('click', '#search-reset', function(e) {
    e.preventDefault();
    return $('.search-results').html('');
  });
  $(document).on('click', '.items-list-element-parent .items-list-title', function() {
    return $(this).parent('.items-list-element-parent').toggleClass('toggled');
  });
  return $(document).on('click', '.bookmark-item', function() {
    var action, bookmark, data, item, type;
    bookmark = $(this);
    type = bookmark.find('span').data('type');
    item = bookmark.find('span').data('item');
    if (bookmark.hasClass('active')) {
      action = 'delete';
    } else {
      action = 'add';
    }
    // return console.log type, item, action
    data = {
      type: type,
      item: item
    };
    bookmark.toggleClass('active');
    return $.ajax({
      url: '/favorites/' + action + '/',
      method: 'POST',
      data: data,
      success: function(data) {
        return console.log(data);
      },
      // if action == 'add'
      //   bookmark.addClass 'active'
      // else if action == 'delete'
      //   bookmark.removeClass 'active'
      error: function(data) {
        data = jQuery.parseJSON(data);
        return console.log(`error: ${data}`);
      }
    });
  });
})(jQuery);

(($) ->

  $(document).ready ->

    $(document).on 'click', '#nav-icon3', ->
      $(this).addClass 'open'
      $('.wrap').addClass 'overlay'

    $(document).on 'hidden.bs.modal', '#menu-modal', (e) ->
      $('#nav-icon3').toggleClass 'open'
      $('.wrap').toggleClass 'overlay'

    $(document).on 'click', '.accordion-item-header', ->
      $(this)
        .siblings('.accordion-item-content')
        .slideToggle()

    $(document).on 'click', '.items-list-element:not(.items-list-element--link)', ->
      $('.items-response-list').remove
      $('.items-list-element').removeClass 'active'
      $(this).addClass 'active'

      object = $(this).data 'object'
      get = $(this).data 'get'
      requestUrl = "/#{object}/#{get}/"
      # console.log requestUrl

      $.ajax
        url: requestUrl
        method: 'GET'
        data:
          id: $(this).data 'id'
        success: (data) ->
          # console.log data
          if data.items.length > 0
            $('.items-response').html data.view;
        error: (data) ->
          console.log "error: #{data}"

    $(document).on 'click', '#btnGroupSearchList .dropdown-item', ->
      _text = $(this).text()
      $('#btnGroupSearchList .dropdown-item').removeClass 'active'
      $(this).addClass 'active'
      $('#btnGroupSearchType span').text _text

    $(document).on 'submit', '#search-form', (e) ->
      e.preventDefault()
      data = $(this).serialize()
      type = $('.dropdown-item.active').data 'type'
      q = $('#btnGroupSearchInput').val()
      # console.log data
      # console.log type

      data = {
        q: q
        type: type
      }

      $.ajax
        url: '/search/index/'
        method: 'GET'
        data: data
        success: (data) ->
          # console.log data
          $('.search-results').html data
          # if data.items.length > 0
          #   $('.items-response').html data.view;
        error: (data) ->
          console.log "error: #{data}"

  $(document).on 'click', '#search-reset', (e) ->
    e.preventDefault()
    $('.search-results').html ''

  $(document).on 'click', '.items-list-element-parent .items-list-title', () ->
    $(this)
    .parent '.items-list-element-parent'
    .toggleClass 'toggled'

  $(document).on 'click', '.bookmark-item', () ->
    bookmark = $(this)

    type = bookmark
    .find 'span'
    .data 'type'

    item = bookmark
    .find 'span'
    .data 'item'

    if bookmark.hasClass 'active'
      action = 'delete'
    else
      action = 'add'

    # return console.log type, item, action

    data = {
      type: type
      item: item
    }

    bookmark.toggleClass 'active'

    $.ajax
      url: '/favorites/' + action + '/'
      method: 'POST'
      data: data
      success: (data) ->
        console.log data
        # if action == 'add'
        #   bookmark.addClass 'active'
        # else if action == 'delete'
        #   bookmark.removeClass 'active'
      error: (data) ->
        data = jQuery.parseJSON(data)
        console.log "error: #{data}"

) jQuery
